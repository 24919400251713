.dashboard-contact {
  width: 440px;
  margin: 100px auto auto;
}
@media screen and (max-width: 768px) {
  .dashboard-contact {
    margin-top: 10px;
  }
}
.dashboard-contact address {
  margin: auto;
  width: 60%;
  font-size: large;
}
.dashboard-contact address a {
  color: black;
}
.dashboard-contact .modal-content {
  background-color: white;
  color: black;
  padding: 30px;
}
.dashboard-contact .modal-content .title {
  font-size: 22px;
  margin-bottom: 20px;
}
.dashboard-contact .modal-content .text {
  margin-top: 12px;
  font-size: 17px;
  text-align: justify;
  text-justify: auto;
}
.dashboard-contact .modal-content .text a {
  color: white;
  font-weight: bold;
  text-transform: lowercase;
}
.dashboard-contact .modal-content .text a:hover {
  text-decoration: underline;
}
.dashboard-contact .modal-content .walcome-button {
  margin-top: 20px;
  font-size: 16px;
}
