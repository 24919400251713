.device-parameter-smog-chart .chart-settings {
  width: 100%;
}
.device-parameter-smog-chart .chart-settings .dates {
  padding: 0 10px 10px 10px;
  margin: 10px auto 20px auto;
  width: 320px;
}
.device-parameter-smog-chart .chart-settings .dates .react-datepicker-wrapper {
  width: auto !important;
}
.device-parameter-smog-chart .chart-settings .dates .react-datepicker__input-container:before {
  left: 14px;
}
.device-parameter-smog-chart .chart-settings .dates .date-input {
  cursor: pointer;
  display: inline-block;
  margin: 0 10px 0 10px;
}
.device-parameter-smog-chart .chart .c3 .c3-axis-y .tick text {
  fill: #1f77b4;
}
.device-parameter-smog-chart .chart .c3 .c3-axis-y2 .tick text {
  fill: #ff7f0e;
}
