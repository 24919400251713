.device-location form {
  margin-bottom: 5px;
}
.device-location .map-button {
  cursor: pointer;
  font-size: 22px;
}
.standard .device-location .map-button {
  color: #3ACFE5;
}
.light .device-location .map-button {
  color: #04A6D8;
}
.location-map .modal-dialog .modal-content {
  width: 800px;
  height: 400px;
}
.location-map .modal-dialog .modal-content .modal-body {
  padding: 0;
}
.location-map .modal-dialog .modal-content .modal-title button {
  margin-left: 10px;
}
.location-map .leaflet-container {
  height: 325px;
  border-radius: 0 0 5px 5px;
  width: 100%;
}
