.dates {
  padding: 5px 0 5px 0;
  margin-bottom: 20px;
}
.dates i {
  cursor: pointer;
}
.dates > div,
.dates > span {
  padding-top: 10px;
}
.dates input {
  border: 0;
  border-radius: 2px;
  text-align: right;
  padding: 0 8px 0 0;
  width: 125px;
  background-color: white;
  height: 35px;
  box-shadow: 0 0 4px lightgray;
}
.dates input:hover {
  border: 0;
  background-color: white;
  box-shadow: 0 0 8px lightgray;
}
.dates input:focus {
  border: 0;
  box-shadow: 0 0 4px gray;
  outline: none;
}
.dates .react-datepicker__input-container:before {
  position: absolute;
  left: 5px;
  top: 5px;
  font-family: 'Material Icons';
  content: "calendar_today";
}
