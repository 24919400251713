.register-device .modal {
  background-color: #000000AA;
  z-index: 100000;
}
.register-device .modal .modal-header {
  border: 0;
}
.register-device .modal .modal-header .close {
  position: absolute;
  right: 25px;
}
.register-device .modal .modal-header .close :active {
  color: darkgray;
}
.register-device .modal .modal-dialog {
  margin: 10% auto;
}
.register-device .modal .modal-content {
  background-color: white;
  color: black;
  padding: 0;
}
.register-device .modal .modal-content .modal-title {
  font-size: 22px;
  margin: auto;
}
.register-device .modal .modal-content .modal-body {
  padding: 10px;
  margin-bottom: 20px;
}
.register-device .modal .modal-content .form-group {
  width: 300px;
  margin: auto;
}
.register-device .modal .modal-content .text {
  margin-top: 12px;
  font-size: 17px;
  text-align: justify;
  text-justify: auto;
}
.register-device .modal .modal-content .text a {
  color: gray;
  font-weight: bold;
  text-transform: lowercase;
}
.register-device .modal .modal-content .text a:hover {
  text-decoration: underline;
}
.register-device .modal .modal-content .btn {
  display: block;
}
.register-device .modal .modal-content .help-block {
  font-size: small;
  margin-top: 0 !important;
}
.register-device .modal .modal-content .form-group {
  margin-bottom: 12px;
}
.register-device .modal .modal-content .register-button {
  background-image: none;
  margin: 28px auto;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  cursor: pointer;
  padding-top: 0;
}
.register-device i {
  position: relative;
  top: 6px;
  left: -16%;
}
