.battery {
  justify-content: center;
  margin: auto;
  width: 50px;
  height: 26px;
  border: 2px solid #555555;
  border-radius: 2px;
  position: relative;
  padding: 2px;
}
.battery .top {
  position: absolute;
  background-color: #555555;
  width: 5px;
  height: 10px;
  border-radius: 2px 2px 0 0;
  right: -7px;
  top: 5px;
}
.battery .network {
  font-size: 26px;
  transform: rotate(90deg);
  margin-left: -4px;
  margin-top: -6px;
}
.battery > .level {
  width: 42px;
  height: 18px;
}
.battery > .level > .text-content {
  position: absolute;
  top: -14px;
  left: 0px;
  text-align: center;
  width: 50px;
  margin: auto;
  font-size: 14px;
  font-weight: 300;
  color: #F8EFE6;
  text-shadow: -1px 0 #555555, 0 1px #555555, 1px 0 #555555, 0 -1px #555555;
}
.battery > .level > .level-value {
  background-color: #555555;
  height: 18px;
}
.battery-tile {
  position: absolute;
  width: 26px;
  height: 50px;
  left: 5px;
  bottom: 5px;
  border: 2px solid white;
  padding: 3px;
  border-radius: 2px;
}
.battery-tile.black {
  border: 2px solid #555555;
}
.battery-tile .top {
  background-color: white;
  width: 10px;
  height: 5px;
  border-radius: 2px 2px 0 0;
  position: absolute;
  top: -5px;
  left: 6px;
}
.battery-tile .top.black {
  background-color: #555555;
}
.battery-tile .network {
  font-size: 26px;
  margin-left: -5px;
  margin-top: 4px;
}
.battery-tile > .level {
  position: absolute;
  width: 19px;
  height: 42px;
  bottom: 2px;
  left: 2px;
}
.battery-tile > .level > .text-content {
  position: absolute;
  left: 10px;
  top: 7px;
  text-align: center;
  width: 19px;
  margin: auto;
  font-size: 14px;
  font-weight: 300;
  z-index: 1;
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  height: 100%;
}
.battery-tile > .level > .level-value {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: white;
  width: 18px;
}
.battery-tile > .level > .level-value.black {
  background-color: #555555;
}
