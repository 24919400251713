.device-settings-panel-button {
  display: inline-block;
  color: #444;
  cursor: pointer;
}
.device-settings-panel-button:hover {
  color: #04A6D8 !important;
}
.device-settings-panel-button i {
  margin-right: 1px !important;
}
.public-api-link {
  position: absolute;
}
.public-api-link .material-icons {
  font-size: 18px;
}
.public-api-link a {
  position: relative;
  top: -2px !important;
}
.standard .shop-link {
  color: #3ACFE5 !important;
  position: absolute;
  margin: -4px 0 0 4px;
}
.light > * .device-details .disconnected-alert,
.light > * .device-details .new-sampling,
.light > * .device-details .info-box,
.light > * .device-details .disconnected-alert,
.light > * .device-details .public-api-link a {
  color: #04A6D8 !important;
}
.light .shop-link {
  color: #04A6D8 !important;
  position: absolute;
  margin: -4px 0 0 4px;
}
.device-details {
  margin: auto;
  padding: 8px 0 12px 24px;
  background-color: #F1F1F1;
  max-width: 100%;
  font-size: 16px;
}
.device-details i,
.device-details a {
  position: relative;
  top: 4px;
  font-size: 20px;
  margin-right: 4px;
}
.device-details .label {
  margin-top: 10px;
  font-weight: 500;
  display: inline-block;
}
.device-details .label .info-box {
  cursor: pointer;
  color: #3ACFE5;
}
.device-details .custom-radio {
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}
.device-details .disconnected-alert {
  color: #3ACFE5;
  top: -5px;
  left: 2px;
  font-size: 12px;
  cursor: pointer;
}
.device-details .form-key-value {
  padding-left: 10px;
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .device-details .mobile-hidden {
    display: none;
  }
}
.device-details .new-sampling {
  font-weight: 300;
  color: #3ACFE5;
  margin-left: 2px;
}
.device-details .public-api-link a {
  font-weight: 300;
  color: #3ACFE5;
  margin-left: 2px;
}
.device-details .sampling .change,
.device-details .mode .change {
  position: relative;
  top: 0;
  font-size: 18px;
  cursor: pointer;
}
.device-details .sampling .change.standard,
.device-details .mode .change.standard {
  color: #3ACFE5;
}
.device-details .sampling .change.light,
.device-details .mode .change.light {
  color: #04A6D8;
}
.device-details .sampling .hidden,
.device-details .mode .hidden {
  display: none;
}
@media screen and (min-width: 767px) {
  .device-details .form-buttons button {
    min-width: 150px;
    margin-left: 20px;
  }
}
.device-details .settings {
  cursor: pointer;
  text-decoration: none;
  margin-left: -10px;
}
.device-details .settings span:first-child {
  font-size: 24px;
}
.device-details .settings:hover {
  text-decoration: none;
}
.device-details .show-grid {
  font-size: 14px;
  padding: 0 10px 0 10px;
  margin-bottom: 5px;
}
.device-details .show-grid > .col-xs-6 {
  padding: 5px;
}
.device-details .show-grid > .col-md-3 {
  padding: 5px;
}
.device-details .show-grid > .col-md-3 > span:first-child {
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
}
.device-details .show-grid > .col-md-3 > span:last-child {
  display: inline-block;
  text-align: right;
}
.device-details .parameter-threshold .label {
  margin-bottom: 10px;
}
.device-details .parameter-threshold .label > span {
  line-height: 50px;
  white-space: nowrap;
}
