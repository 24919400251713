.device-user-list {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 110px);
  border-radius: 5px;
  padding: 0;
}
.device-user-list .device-panel {
  margin: 15px;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 10px #CCC;
}
.device-user-list .device-panel .device-name {
  font-size: 14px;
}
.device-user-list .device-panel .item-base {
  display: inline-block;
  text-align: center;
  vertical-align: center;
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
}
.device-user-list .device-panel .item-owner {
  display: inline-block;
  text-align: center;
  vertical-align: center;
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
  position: relative;
  top: -6px;
  background-color: lightblue;
  height: 40px;
  width: 40px;
}
.device-user-list .device-panel .item-owner span {
  position: relative;
  top: 2px;
  line-height: 1;
}
.device-user-list .device-panel .item {
  display: inline-block;
  text-align: center;
  vertical-align: center;
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
  background-color: antiquewhite;
}
.device-user-list .device-panel .item span {
  position: relative;
  top: -4px;
  line-height: 1;
}
.device-user-list .device-panel .item i {
  position: relative;
  top: 1px;
  left: 4px;
}
