.standard > * .tile.normal {
  background: linear-gradient(to right, #F8EFE6, #F1F1F1);
  color: #5B5151;
}
.standard > * .tile.normal > * .badge {
  background-color: #F6ECD9;
}
.standard > * .tile > .value-list span {
  background-color: #F6ECD9;
}
.light > * .tile.normal {
  background-color: white;
  color: #5B5151;
  border: 0;
}
.light > * .tile > .value-list span {
  background-color: white;
}
.tile {
  position: relative;
  padding: 24px 10px 10px;
  margin: 10px;
  height: 120px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  min-width: 240px;
  width: 320px;
  box-shadow: 0 0 8px lightgray;
}
.tile.smog {
  font-size: 16px;
}
.tile.smog .lastUpdate {
  position: absolute;
  font-size: 14px;
  top: 0;
  right: 90px;
}
.tile.smog .row {
  width: 100%;
  padding-left: 10px;
  padding-top: 4px;
}
.tile.smog .row .badge {
  font-size: 14px;
  width: 174px;
  margin-left: 16px;
  border: 1px solid lightgray;
}
.tile.smog .row .value-row .name {
  min-width: 86px;
  display: inline-block;
  font-weight: normal;
  text-align: left;
}
.tile.smog .row .value-row .value {
  font-size: 85%;
  width: 54px;
  display: inline-block;
}
.tile.smog .content {
  display: flex;
}
.tile.smog .values {
  margin: auto;
  width: 100%;
}
.tile.smog .values .row {
  margin: 0;
  padding: 2px 2px 2px 0;
}
.tile > .name {
  margin-top: 5px;
  text-align: center;
  width: 100%;
  overflow: hidden;
  max-height: 25px;
}
.tile > .value-list,
.tile .value {
  display: inline-block;
  text-align: center;
  width: 100%;
  font-size: 30px;
}
.tile > .value-list > .below,
.tile .value > .below {
  color: #67c2ef;
}
.tile > .value-list > .above,
.tile .value > .above {
  color: #ff5454;
}
.tile > .value-list {
  display: inline-block;
  text-align: center;
  width: 100%;
  font-size: 18px;
}
.tile > .value-list > span {
  display: inline-block;
  margin: 4px;
  padding: 2px 4px 2px 4px;
  border-radius: 4px;
  border: 1px solid lightgray;
}
.tile > .lastUpdate {
  text-align: center;
  width: 100%;
  font-size: 14px;
}
.tile > .subscription-sign {
  position: absolute;
  right: 12px;
  bottom: 10px;
  font-size: 18px;
}
.tile > .sensor-list {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
  padding: 5px;
}
.tile > .online {
  position: absolute;
  display: inline-block;
  top: 5px;
  right: 15px;
  font-size: 16px;
}
.tile.normal .text-content {
  color: #F8EFE6;
  text-shadow: -1px 0 #5B5151, 0 1px #5B5151, 1px 0 #5B5151, 0 -1px #5B5151;
}
