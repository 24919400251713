body {
  background-color: #222222;
}
.standard > * .formContent {
  background-color: #3ACFE5;
}
.standard > * .formContent > * label {
  background-color: #3ACFE5;
}
.standard > * .underlineHover {
  color: #3ACFE5;
}
.standard > * .underlineHover:after {
  background-color: #3ACFE5;
}
.standard > * .underlineHover:hover {
  color: #3ACFE5;
}
.light > * .formContent {
  background-color: #04A6D8;
}
.light > * .formContent > * label {
  background-color: #04A6D8;
}
.light > * .underlineHover {
  color: #04A6D8;
}
.light > * .underlineHover:after {
  background-color: #04A6D8;
}
.light > * .underlineHover:hover {
  color: #04A6D8;
}
.register .model-error,
.register-success .model-error,
.login .model-error,
.forgot-password .model-error,
.confirm-email .model-error {
  width: 90%;
  margin: auto;
}
.register .logo-box,
.register-success .logo-box,
.login .logo-box,
.forgot-password .logo-box,
.confirm-email .logo-box {
  width: 180px;
  height: 85px;
  top: 40px;
  margin: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  vertical-align: bottom;
  position: relative;
  border: 18px solid #222222;
  background-color: #222222;
  border-radius: 20px;
  z-index: 100;
}
.register .logo-box.ipomiar,
.register-success .logo-box.ipomiar,
.login .logo-box.ipomiar,
.forgot-password .logo-box.ipomiar,
.confirm-email .logo-box.ipomiar {
  background-image: url("../images/small-logo-ipomiar.png");
}
.register .logo-box.isensum,
.register-success .logo-box.isensum,
.login .logo-box.isensum,
.forgot-password .logo-box.isensum,
.confirm-email .logo-box.isensum {
  background-image: url("../images/small-logo-isensum.png");
}
.register .logo-box.dcx,
.register-success .logo-box.dcx,
.login .logo-box.dcx,
.forgot-password .logo-box.dcx,
.confirm-email .logo-box.dcx {
  background-image: url("../images/small-logo-dcx.png");
  height: 100px;
}
.register .logo-box.pudliszki,
.register-success .logo-box.pudliszki,
.login .logo-box.pudliszki,
.forgot-password .logo-box.pudliszki,
.confirm-email .logo-box.pudliszki {
  background-image: url("../images/small-logo-pudliszki.png");
  height: 100px;
}
.register .wrapper,
.register-success .wrapper,
.login .wrapper,
.forgot-password .wrapper,
.confirm-email .wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  position: relative;
  padding: 0;
}
.register .formContent,
.register-success .formContent,
.login .formContent,
.forgot-password .formContent,
.confirm-email .formContent {
  padding: 60px 20px 20px;
  width: 90%;
  max-width: 450px;
  position: relative;
  text-align: center;
  border-radius: 8px 8px 0 0;
}
.register .formContent .form-control,
.register-success .formContent .form-control,
.login .formContent .form-control,
.forgot-password .formContent .form-control,
.confirm-email .formContent .form-control {
  margin-bottom: 10px;
}
.register .formContent .login-message,
.register-success .formContent .login-message,
.login .formContent .login-message,
.forgot-password .formContent .login-message,
.confirm-email .formContent .login-message {
  display: block;
  font-size: 16px;
  font-weight: bold;
  padding: 6px;
  margin: 0 0 10px;
  color: white;
  border-radius: 4px;
}
.register .formContent .login-message.invalid-feedback,
.register-success .formContent .login-message.invalid-feedback,
.login .formContent .login-message.invalid-feedback,
.forgot-password .formContent .login-message.invalid-feedback,
.confirm-email .formContent .login-message.invalid-feedback {
  background-color: #dc3545;
}
.register .formContent .login-message.valid-feedback,
.register-success .formContent .login-message.valid-feedback,
.login .formContent .login-message.valid-feedback,
.forgot-password .formContent .login-message.valid-feedback,
.confirm-email .formContent .login-message.valid-feedback {
  background-color: #155724;
}
.register .formContent h3,
.register-success .formContent h3,
.login .formContent h3,
.forgot-password .formContent h3,
.confirm-email .formContent h3 {
  margin-bottom: 20px;
}
.register .formContent label,
.register-success .formContent label,
.login .formContent label,
.forgot-password .formContent label,
.confirm-email .formContent label,
.register .formContent h3,
.register-success .formContent h3,
.login .formContent h3,
.forgot-password .formContent h3,
.confirm-email .formContent h3 {
  color: #f6f6f6;
}
.register .formContent .or,
.register-success .formContent .or,
.login .formContent .or,
.forgot-password .formContent .or,
.confirm-email .formContent .or {
  width: 100%;
  border-bottom: 1px solid white;
  height: 20px;
  margin: 10px 0 20px 0;
}
.register .formContent .or label,
.register-success .formContent .or label,
.login .formContent .or label,
.forgot-password .formContent .or label,
.confirm-email .formContent .or label {
  padding: 0 10px 0 10px;
  margin: 5px;
}
.register .formContent .google-provider,
.register-success .formContent .google-provider,
.login .formContent .google-provider,
.forgot-password .formContent .google-provider,
.confirm-email .formContent .google-provider {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("../images/btn_google_signin_light_normal_web@2x.png");
  width: 200px;
  height: 50px;
  cursor: pointer;
  margin: auto;
  color: inherit;
  border: none;
  padding: 0;
  outline: inherit;
}
.register .formFooter,
.register-success .formFooter,
.login .formFooter,
.forgot-password .formFooter,
.confirm-email .formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px 5px 25px 5px;
  text-align: center;
  width: 90%;
  max-width: 450px;
  border-radius: 0 0 4px 4px;
}
.register .formFooter .link-container,
.register-success .formFooter .link-container,
.login .formFooter .link-container,
.forgot-password .formFooter .link-container,
.confirm-email .formFooter .link-container {
  margin: auto;
  width: max-content;
}
.register .underlineHover:after,
.register-success .underlineHover:after,
.login .underlineHover:after,
.forgot-password .underlineHover:after,
.confirm-email .underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  content: "";
  transition: width 0.2s;
}
.register .underlineHover:hover,
.register-success .underlineHover:hover,
.login .underlineHover:hover,
.forgot-password .underlineHover:hover,
.confirm-email .underlineHover:hover {
  text-decoration: none !important;
}
.register .underlineHover:hover:after,
.register-success .underlineHover:hover:after,
.login .underlineHover:hover:after,
.forgot-password .underlineHover:hover:after,
.confirm-email .underlineHover:hover:after {
  width: 100%;
}
.animation-box {
  /* Simple CSS3 Fade-in Animation */
}
.animation-box .fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.animation-box .fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}
.animation-box .fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.animation-box .fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.animation-box .fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.animation-box .fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}
.register .formContent {
  border-radius: 8px 8px 0 0;
  max-width: initial;
}
@media (min-width: 768px) {
  .register .formContent {
    border-radius: 8px 0 0 8px;
  }
}
.register .recaptcha-box {
  width: 100%;
  margin-bottom: 1rem;
}
.register .recaptcha-box div div {
  margin: auto;
}
.register .statement {
  border-radius: 0 0 8px 8px;
  background-color: white;
  width: 90%;
  font-size: 13px;
  padding: 22px;
  padding-top: 60px;
}
@media (min-width: 768px) {
  .register .statement {
    border-radius: 0 8px 8px 0;
  }
}
