.tooltip-content {
  font-size: 14px;
}
.tooltip-content .date {
  border-bottom: 1px solid gray;
  margin-bottom: 6px;
}
.tooltip-content .series-name {
  display: inline-block;
  margin-right: 10px;
}
.tooltip-content .value {
  font-weight: normal;
}
.chart {
  box-shadow: 0 0 8px lightgray;
  border-radius: 4px;
  background-color: white;
  padding: 11px 0 12px 0;
  text-align: center;
  margin: 15px;
}
.chart .title {
  font-size: 18px;
  text-align: center;
}
.chart .chart-content {
  display: block;
  margin: 10px;
}
