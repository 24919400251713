.standard > * .dashboard-grid-item.normal {
  background: linear-gradient(to right, #F8EFE6, #F1F1F1);
}
.light > * .dashboard-grid-item.normal {
  background-color: white;
  border: 0;
}
.dashboard-grid-item {
  position: relative;
  padding: 8px;
  padding-top: 12px;
  margin: 5px;
  height: 120px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  min-width: 155px;
  width: 155px;
  border: 1px solid lightgray;
}
.dashboard-grid-item.below {
  background-color: #67c2ef;
}
.dashboard-grid-item.above {
  background-color: #ff5454;
}
.dashboard-grid-item > .name {
  text-align: center;
  margin-top: 5px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 40px;
  font-size: 14px;
}
.dashboard-grid-item > .value {
  display: inline-block;
  text-align: center;
  width: 100%;
  font-size: 26px;
}
.dashboard-grid-item > .lastUpdate {
  text-align: center;
  width: 100%;
  font-size: 12px;
}
.dashboard-grid-item > .online {
  position: absolute;
  display: inline-block;
  top: 2px;
  right: 2px;
}
.dashboard-grid-item > .online .material-icons {
  font-size: 18px;
}
.dashboard-grid-item.normal {
  color: #5B5151;
}
.dashboard-grid-item.normal .text-content {
  color: #F8EFE6;
  text-shadow: -1px 0 #5B5151, 0 1px #5B5151, 1px 0 #5B5151, 0 -1px #5B5151;
}
