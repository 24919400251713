.supervisor {
  padding: 10px;
  margin: 0;
  width: 100%;
}
.supervisor h3 {
  margin-bottom: 15px;
}
.supervisor .scroll-area {
  height: calc(100vh - 52px) !important;
  width: 100%;
  margin: 0;
}
.supervisor .list-item {
  padding-left: 10px;
  padding-right: 10px;
}
.light .supervisor {
  background-color: #F1F1F1;
}
