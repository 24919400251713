body {
  background-color: #222222;
}
.portal .navbar-dark {
  background-color: #222222;
}
.portal .container-box {
  min-width: 320px;
  margin: auto;
  padding: 10px;
}
.portal .enter-box {
  width: 500px;
  max-width: 100%;
  height: 420px;
  margin: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  vertical-align: bottom;
  position: relative;
}
.portal .enter-box.ipomiar {
  background-image: url("../images/logo-ipomiar.png");
}
.portal .enter-box.isensum {
  background-image: url("../images/logo-isensum.png");
}
.portal .enter-box.dcx {
  background-image: url("../images/logo-dcx.png");
}
.portal .enter-box.pudliszki {
  background-image: url("../images/logo-pudliszki.png");
}
.portal .enter-box .actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  position: absolute;
  padding: 0 10px 0 10px;
  bottom: -60px;
}
.portal .enter-box .actions div {
  cursor: pointer;
  max-width: 400px;
  width: 30%;
  min-width: 140px;
  color: white;
  font-size: 20px;
  text-decoration: none;
}
