.device-parameter-converter .advanced-button {
  text-align: right;
  width: 100%;
  cursor: pointer;
  font-size: 12px;
  color: #3ACFE5;
}
.device-parameter-converter.standard .advanced-button {
  color: #3ACFE5;
}
.device-parameter-converter.standard .scaling-button {
  color: #3ACFE5;
}
.device-parameter-converter.light .advanced-button {
  color: #04A6D8;
}
.device-parameter-converter.light .scaling-button {
  color: #04A6D8;
}
.device-parameter-converter .converter-image-container {
  width: 340px;
  margin: auto;
  display: grid;
  grid-template-columns: 0.1fr 1.9fr;
  grid-template-rows: 1.9fr 0.1fr;
  gap: 0 0;
  grid-template-areas: ". ." ". .";
}
.device-parameter-converter .converter-image-container .y-axis-label {
  text-orientation: mixed;
  writing-mode: vertical-lr;
  text-align: center;
}
.device-parameter-converter .converter-image-container .x-axis-label {
  text-align: center;
}
.device-parameter-converter .converter-help-image {
  color: inherit;
  border: none;
  padding: 0;
  outline: inherit;
  display: inline-block;
  width: 300px;
  height: 170px;
  background: url("../images/converter-help.png") no-repeat center;
  background-size: 300px;
}
.device-parameter-converter .scaling-button {
  cursor: pointer;
  background-color: transparent !important;
  margin: 0 4px;
  padding: 1px;
  top: -3px;
  position: relative;
}
.device-parameter-converter .scaling-modal {
  min-width: 200px;
  width: 40vw;
  max-width: 60vw;
}
.device-parameter-converter .modal-dialog .modal-content .modal-title button {
  margin-left: 20px;
}
.device-parameter-converter .modal-dialog .modal-content .scaling-edit-row {
  margin-bottom: 10px;
}
