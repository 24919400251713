.device-parameter-settings .info-mark {
  position: relative;
  top: 0;
  left: 2px;
  font-size: 18px;
  cursor: pointer;
}
.device-parameter-settings .info-mark.standard {
  color: #3ACFE5;
}
.device-parameter-settings .info-mark.light {
  color: #04A6D8;
}
.device-parameter-settings .parameter-name-label {
  text-align: right;
  padding-top: 2px;
}
.device-parameter-settings .parameter-name-text-box {
  display: inline-block !important;
  width: calc(100% - 50px);
}
.device-parameter-settings .modal-content {
  min-width: 1024px;
  max-width: 1280px;
}
.device-parameter-settings.standard .sensors-button {
  border: 1px solid #3ACFE5;
  color: #3ACFE5;
}
.device-parameter-settings.light .sensors-button {
  border: 1px solid #04A6D8;
  color: #04A6D8;
}
.device-parameter-settings .sensors-button {
  cursor: pointer;
  background-color: transparent !important;
  border-radius: 4px;
  margin: 4px;
  min-height: 40px;
  padding: 4px;
}
.device-parameter-settings .sensors-button span {
  position: relative;
  top: 4px;
  font-size: 20px;
  margin-right: 4px;
}
.device-parameter-settings .sensor-modal {
  min-width: 800px;
  width: 80vw;
  max-width: 90vw;
}
.device-parameter-settings .modal-dialog .modal-content .modal-title button {
  margin-left: 20px;
}
.device-parameter-settings .modal-dialog .modal-content .sensor-edit-row {
  margin-bottom: 10px;
}
.standard .device-sensor-names .sensors-button {
  color: #3ACFE5;
}
.light .device-sensor-names .sensors-button {
  color: #04A6D8;
}
