.map-trend-chart {
  margin-top: 10px;
  max-width: 285px;
  min-width: 285px;
}
.map-trend-chart .c3 {
  max-height: 150px !important;
}
.map-trend-chart .c3 svg {
  margin-left: -10px;
}
.map-trend-chart .c3-axis .tick line {
  display: none;
}
.map-trend-chart .c3-axis .domain {
  display: none;
}
