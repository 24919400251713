.dashboard-welcome .modal {
  top: 20%;
}
.dashboard-welcome .modal .modal-content {
  background-color: #3ACFE5;
  color: white;
  padding: 30px;
}
.dashboard-welcome .modal .modal-content .title {
  font-size: 22px;
  margin-bottom: 20px;
}
.dashboard-welcome .modal .modal-content .text {
  margin-top: 12px;
  font-size: 17px;
  text-align: justify;
  text-justify: auto;
}
.dashboard-welcome .modal .modal-content .text a {
  color: white;
  font-weight: bold;
  text-transform: lowercase;
}
.dashboard-welcome .modal .modal-content .text a:hover {
  text-decoration: underline;
}
.dashboard-welcome .welcome-button {
  margin-top: 20px;
  font-size: 16px;
  cursor: pointer;
  padding-top: 1px;
}
.dashboard-welcome .welcome-button i {
  position: relative;
  top: 5px;
}
