.logo {
  height: 68px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.logo.isensum {
  height: 80px;
  background-image: url("../images/small-logo-dark-isensum.png");
}
.logo.ipomiar {
  height: 80px;
  background-image: url("../images/small-logo-dark-ipomiar.png");
}
.logo.dcx {
  height: 96px;
  background-image: url("../images/small-logo-dcx.png");
}
.logo.breath-box {
  height: 140px;
  background-image: url("../images/breath-box-logo.png");
}
.logo.pudliszki {
  height: 140px;
  background-image: url("../images/small-logo-pudliszki.png");
}
.demo-box {
  background-color: red;
  color: white;
  position: absolute;
  border-radius: 5px;
  padding: 2px 5px 2px 5px;
  right: 5px;
  top: 4px;
  font-size: 11px;
  min-width: 10px;
  min-height: 10px;
}
