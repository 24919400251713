.notification-section {
  height: calc(100vh - 54px);
  box-shadow: inset 0px 5px 8px -8px, inset 0px 0px 0px 0px;
}
.notification-content {
  background-color: #F1F1F1;
  width: 100%;
  height: 100%;
  padding-top: 10px;
}
.notification-content .custom-radio {
  display: flex;
  margin: 5px;
  align-self: center;
}
.notification-content button:not(.dropdown-toggle) {
  display: inline-flex;
}
.notification-content .form-control-inline {
  display: inline-block;
}
.notification-content .save-buttons button {
  margin: 20px 0px 10px 10px;
}
.notification-content .toggle-handle.btn-xs {
  top: -1px;
  height: 30px;
  border-radius: 0;
}
.notification-content .notification-toggle {
  margin: 0 10px 0 10px;
}
.notification-content .center-element {
  align-self: center;
}
.notification-content .center-element span:nth-of-type(1) {
  padding-top: 14px;
}
.notification-content .row {
  min-height: 60px;
}
.notification-content .row.content {
  max-width: 1280px;
  margin: auto;
}
.notification-content .no-pointer {
  cursor: initial !important;
}
.notification-content .no-pointer span {
  cursor: initial !important;
}
.notification-content .flex-box {
  display: flex;
  align-items: stretch;
  margin: auto auto 20px;
}
.notification-content .separator {
  border-bottom: 1px solid #AAA;
  padding-bottom: 30px;
  margin-bottom: 30px !important;
}
.notification-content .alert-logo {
  font-size: 18px;
  margin-right: 15px;
}
.notification-list > div {
  max-width: 600px;
}
.notification-list .remove {
  text-align: center;
}
.notification-list td {
  vertical-align: middle;
}
.notification-list td.action {
  text-align: center;
}
.notification-list th {
  text-align: center;
}
.notification-list th.phone-number {
  text-align: left;
}
.notification-list button:not(.dropdown-toggle) {
  display: inline-flex;
}
.report-company-data-dialog .title-label {
  padding: 5px;
}
.register-phone-dialog .modal-dialog {
  max-width: 400px;
}
.register-phone-dialog .modal-dialog i {
  position: relative;
  top: 5px;
}
.register-phone-dialog .icon {
  font-size: 50px;
  margin: 20px auto auto -10px;
}
.register-phone-dialog .title-label {
  padding-bottom: 10px;
}
.register-phone-dialog .phoneNumber {
  font-size: 22px;
  height: 40px;
  display: table;
  margin: 0 auto;
}
.register-phone-dialog .phoneNumber input {
  display: inline-block;
  font-size: 22px;
  width: 202px;
}
.register-phone-dialog .rodo {
  font-size: 11px;
  padding: 20px 20px 0 20px;
  color: gray;
}
.register-phone-dialog .rodo ol {
  text-align: justify;
  padding-left: 12px;
}
.register-phone-dialog .error-message {
  padding-top: 10px;
  color: red;
}
.register-phone-dialog .margin {
  margin: 0 !important;
}
.register-phone-dialog .title {
  position: absolute;
  left: 16px;
  cursor: pointer;
  text-align: center;
  display: block;
  text-decoration: none;
}
.register-phone-dialog .phone-number-code {
  text-align: center;
  font-size: 24px;
  width: 180px;
  height: 40px;
  display: table;
  margin: 0 auto !important;
}
.register-phone-dialog .regulations {
  width: 100%;
}
.register-phone-dialog .comment {
  padding-top: 10px;
}
