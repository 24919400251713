.device-data {
  background-color: #fbfbfb;
  width: 100%;
}
.device-data .filter-button {
  margin-left: 10px;
  background-color: white;
  position: relative;
  top: -1px;
  box-shadow: 0 0 4px lightgray;
  border: none;
}
.device-data i {
  display: inline-flex;
  vertical-align: middle;
}
.device-data .chart {
  border: 0;
  box-shadow: 0 0 8px lightgray;
  border-radius: 4px;
}
.device-data .container {
  padding: 0;
  max-width: 90%;
}
.device-data .container .col-md-8 {
  padding: 0;
}
.device-data .settings {
  width: 101%;
}
.device-data .settings .options-panel {
  margin: auto;
  width: 100%;
}
.device-data .settings .options-panel .right-options-panel {
  display: inline-block;
  float: right;
  margin-top: 16px;
  padding: 5px;
  height: 35px;
  border-radius: 2px;
  background-color: white;
  box-shadow: 0 0 4px lightgray;
}
.device-data .settings .options-panel .item {
  display: inline-block;
  margin: 0 30px 0 0;
}
.device-data .settings .options-panel .item .react-datepicker-wrapper {
  margin-left: 20px;
  width: auto;
}
.device-data .settings .options-panel .item .online {
  display: inline-block;
  position: relative;
  margin-left: 5px;
  margin-top: -1px;
}
.device-data .report-click {
  cursor: pointer;
  text-decoration: none;
}
.device-data .device-grid {
  box-shadow: 0 0 8px lightgray;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  margin-top: 15px;
}
.device-data .device-grid .modal {
  top: 50px;
}
@media screen and (max-width: 767px) {
  .device-data .device-grid .table-responsive {
    padding: 0;
    margin: 0;
    border: 0;
  }
}
.device-data .device-grid .table-header {
  padding: 0;
  margin: 0;
}
.device-data .device-grid .scrollarea {
  height: 500px !important;
}
.device-data .device-grid .threshold-max {
  color: #ff5454;
}
.device-data .device-grid .threshold-min {
  color: #67c2ef;
}
.device-data .device-grid .data-warning {
  color: #e0a800;
}
.device-data .device-grid .hidden {
  display: none;
}
.device-data .device-grid th:first-child {
  width: 0;
}
.device-data .device-grid th {
  border-top: 0;
  text-align: center;
  width: 50%;
}
.device-data .device-grid th .grid-row-date {
  width: 55%;
}
.device-data .device-grid td:first-child {
  width: 0;
}
.device-data .device-grid tr.grid-row {
  cursor: default;
}
.device-data .device-grid tr.grid-row:hover {
  background-color: #F1F1F1;
}
.device-data .device-grid tr.grid-row .grid-row-date {
  width: 55%;
}
.device-data .device-grid td {
  width: 50%;
  text-align: center;
}
.device-data .device-grid td .remove {
  cursor: pointer;
}
.device-data .device-grid td .remove i {
  margin-top: -2px;
  position: absolute;
}
.device-data .device-grid .table {
  margin-bottom: 0;
}
.device-data .device-grid th {
  font-weight: 300;
  font-size: 16px;
}
