.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 10px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #CCC;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.standard > * input:checked + .slider {
  background-color: #3ACFE5;
}
.standard > * input:focus + .slider {
  box-shadow: 0 0 1px #3ACFE5;
}
.light > * input:checked + .slider {
  background-color: #04A6D8;
}
.light > * input:focus + .slider {
  box-shadow: 0 0 1px #04A6D8;
}
input:disabled + .slider {
  background-color: #CCC !important;
  cursor: initial !important;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
