input::file-selector-button {
  margin-top: 0;
  background-color: #3ACFE5;
  color: white;
  height: 36px;
  border-width: 0;
  border-radius: 3px 0 0 3px;
  cursor: pointer;
}
.planner-canvas {
  border: 1px solid #ccc;
  border-radius: 6px;
  background-image: url("../images/floor-background.png");
}
.space-planner-desc {
  text-align: center;
  padding-top: 100px;
  font-size: 22px;
  color: darkgray;
}
.planner-canvas-scroll {
  height: calc(100vh - 200px) !important;
  width: calc(100% - 50px) !important;
  overflow: auto !important;
  margin: 10px;
}
