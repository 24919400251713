.dropdown-item.active {
  background-color: #3ACFE5 !important;
}
.btn:focus {
  box-shadow: none;
}
.btn-primary:focus {
  box-shadow: none;
}
.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-primary:active:focus {
  box-shadow: none;
}
.btn:active:focus {
  box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.standard > * .btn-primary {
  background-color: #3ACFE5 !important;
  border-color: #3ACFE5 !important;
}
.standard > * .btn-primary:hover {
  background-color: #2ABFD5 !important;
  border-color: #2ABFD5 !important;
}
.standard > * .btn-success {
  background-color: #fff64f !important;
  border-color: #fff64f !important;
  color: #555 !important;
}
.standard > * .btn-success:hover {
  background-color: #ebe014 !important;
  border-color: #ebe014 !important;
  color: #555 !important;
}
.standard > .left-panel {
  background-color: #222222;
  color: white;
}
.standard > .left-panel .nav div.active {
  background-color: black;
  color: white;
}
.standard > * .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #3ACFE5 !important;
  background-color: #3ACFE5 !important;
}
.light > * .btn-primary {
  background-color: #04A6D8 !important;
  border-color: #04A6D8 !important;
}
.light > * .btn-primary:hover {
  background-color: #02BEE6 !important;
  border-color: #02BEE6 !important;
}
.light > .left-panel {
  background-color: #F1F1F1;
  color: #222222;
}
.light > .left-panel .nav div.active {
  background-color: lightgray;
}
.light > * .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #04A6D8 !important;
  background-color: #04A6D8 !important;
}
.main-container {
  background-color: #fbfbfb;
}
.main-container .right-panel {
  padding: 0;
  background-color: #fbfbfb;
}
@media (min-width: 768px) {
  .main-container .right-panel {
    position: fixed;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }
}
.main-container .left-panel {
  display: none;
  padding: 0;
  z-index: 1;
  box-shadow: 2px 0 5px lightgrey;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}
.main-container .left-panel .nav div {
  padding: 2px 15px 8px 5px;
  width: 100%;
  border: none;
  cursor: pointer;
}
.main-container .left-panel .nav div i {
  position: relative;
  top: 5px;
}
.main-container .left-panel .nav div:hover {
  border: none;
}
.main-container .left-panel .nav div.active {
  border: none;
}
.main-container .left-panel .nav div.active:focus {
  border: none !important;
  outline-color: none !important;
}
.main-container .left-panel .nav div.active:visited {
  border: none !important;
  outline-color: none !important;
}
.main-container .left-panel .scrollarea-area {
  display: none;
}
@media (min-width: 768px) {
  .main-container .left-panel .scrollarea-area {
    max-height: calc(100vh - 110px);
    display: initial;
    position: absolute;
    width: 100% !important;
  }
}
@media (min-width: 768px) {
  .main-container .left-panel {
    display: block;
  }
}
.main-container .sidebar-left {
  height: 100%;
  width: 50px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
}
.main-container .sidebar-left .nav div {
  padding: 2px 15px 8px 10px;
}
.main-container .sidebar-right {
  left: 50px;
}
.expand-collapse-horizontal {
  color: #3ACFE5;
  border: 2px solid #3ACFE5;
  border-radius: 20px;
  cursor: pointer;
  margin: 10px;
}
.add-device-button {
  display: inline-flex !important;
  justify-content: center;
  width: 100%;
  height: 38px;
  border-radius: 0 !important;
  background-image: none;
  cursor: pointer;
}
@media (min-width: 768px) {
  .add-device-button {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.dashboard-container {
  width: 100%;
  margin: 0;
}
.dashboard-container .nav-pills li a {
  border-radius: 0px;
}
.box-tile {
  padding: 10px;
}
.online {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.no-data {
  width: 400px;
  height: 400px;
  margin: auto;
  font-size: 24px;
  color: gray;
  font-weight: 200;
  text-align: center;
  padding-top: 50px;
}
.no-data span:first-child {
  display: inline-block;
  text-align: center;
  width: 30px;
  height: 30px;
  font-size: 20px;
  line-height: 28px;
  border: 2px solid gray;
  border-radius: 50%;
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em;
  color: #000;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") no-repeat;
  background-size: 60%;
  border: 0;
  border-radius: 0.25rem;
  /* opacity: .5; */
}
