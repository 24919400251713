.device-form {
  padding: 10px;
  margin: 15px 0;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px #CCC;
}
.device-form .user-add-error {
  color: #721c24;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.device-form .device-choose-list {
  margin: 0;
  padding: 0;
  overflow-y: auto;
  max-height: calc(100vh - 390px);
}
