.quality-index-legend {
  display: grid;
  grid-template-columns: min-content auto auto auto;
  padding: 10px;
}
.quality-index-legend .grid-item-header {
  padding: 4px;
  text-align: center;
  grid-column-start: 1;
  grid-column-end: 3;
}
.quality-index-legend .grid-item {
  padding: 4px;
  text-align: center;
}
.quality-index-legend .grid-item.selected {
  font-weight: bolder;
}
.quality-index-legend .grid-item .qindex {
  width: 20px;
  height: 20px;
  border-radius: 25%;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.quality-index-legend .grid-item .qindex.index-0 {
  background-image: linear-gradient(to bottom right, lightgray, lightgray);
  box-shadow: 0px 0px 4px 1px lightgray;
}
.quality-index-legend .grid-item .qindex.index-1 {
  background-image: linear-gradient(to bottom right, #84D54C, #67CC32);
  box-shadow: 0px 0px 4px 1px #67CC32;
}
.quality-index-legend .grid-item .qindex.index-2 {
  background-image: linear-gradient(to bottom right, #54D3FF, #00BDFF);
  box-shadow: 0px 0px 4px 1px #00BDFF;
}
.quality-index-legend .grid-item .qindex.index-3 {
  background-image: linear-gradient(to bottom right, #ECCC42, #E9C127);
  box-shadow: 0px 0px 4px 1px #E9C127;
}
.quality-index-legend .grid-item .qindex.index-4 {
  background-image: linear-gradient(to bottom right, #EC9246, #E9782A);
  box-shadow: 0px 0px 4px 1px #E9782A;
}
.quality-index-legend .grid-item .qindex.index-5 {
  background-image: linear-gradient(to bottom right, #ED6052, #EA393A);
  box-shadow: 0px 0px 4px 1px #EA393A;
}
.quality-index-legend .grid-item .qindex.index-6 {
  background-image: linear-gradient(to bottom right, #C03B6A, #B10258);
  box-shadow: 0px 0px 4px 1px #B10258;
}
.quality-index-legend .grid-item .qindex div {
  margin: auto;
  line-height: 0;
  position: relative;
  top: -1px;
  color: black;
  font-weight: 500;
}
.quality-index {
  min-width: 50px;
  height: 50px;
  border-radius: 25%;
  display: flex;
  align-items: center;
  font-size: 28px;
  margin-right: 10px;
  margin-top: 2px;
}
.quality-index.index-0 {
  background-image: linear-gradient(to bottom right, lightgray, lightgray);
  box-shadow: 0px 0px 4px 1px lightgray;
}
.quality-index.index-1 {
  background-image: linear-gradient(to bottom right, #84D54C, #67CC32);
  box-shadow: 0px 0px 4px 1px #67CC32;
}
.quality-index.index-2 {
  background-image: linear-gradient(to bottom right, #54D3FF, #00BDFF);
  box-shadow: 0px 0px 4px 1px #00BDFF;
}
.quality-index.index-3 {
  background-image: linear-gradient(to bottom right, #ECCC42, #E9C127);
  box-shadow: 0px 0px 4px 1px #E9C127;
}
.quality-index.index-4 {
  background-image: linear-gradient(to bottom right, #EC9246, #E9782A);
  box-shadow: 0px 0px 4px 1px #E9782A;
}
.quality-index.index-5 {
  background-image: linear-gradient(to bottom right, #ED6052, #EA393A);
  box-shadow: 0px 0px 4px 1px #EA393A;
}
.quality-index.index-6 {
  background-image: linear-gradient(to bottom right, #C03B6A, #B10258);
  box-shadow: 0px 0px 4px 1px #B10258;
}
.quality-index div {
  margin: auto;
  line-height: 0;
  position: relative;
  top: -1px;
  font-weight: 500;
}
