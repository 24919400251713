.top-menu {
  background-color: white;
  margin: 0;
  color: black;
  height: 54px;
  border: 0;
}
.top-menu .nav-item {
  color: black;
  opacity: 0.5;
  padding: 15px;
  cursor: pointer;
}
.top-menu .nav-item.active {
  opacity: 1;
}
.top-menu .nav-item:hover {
  opacity: 0.7;
}
.top-menu .nav-link {
  padding: 15px;
}
.top-menu i {
  font-size: 20px;
  position: relative;
  top: 4px;
}
.top-menu .container {
  width: 100%;
  z-index: 99999;
  position: absolute;
  background-color: #fbfbfb;
  border-bottom: 1px solid lightgray;
}
@media (min-width: 768px) {
  .top-menu .username {
    display: none;
  }
}
@media (min-width: 768px) {
  .top-menu .logout {
    display: none;
  }
}
.shadow-top {
  box-shadow: inset 0px 5px 8px -8px, inset 0px 0px 0px 0px;
  position: relative;
  z-index: 2;
  height: 20px;
  margin-bottom: -20px;
}
@media (min-width: 991px) {
  .dashboard-link {
    display: none;
  }
}
@media (max-width: 991px) {
  .navbar-collapse {
    background: white;
    z-index: 999;
  }
}
