.standard > * .list-item {
  background: linear-gradient(to right, #F8EFE6, #F1F1F1);
}
.standard > * .drag-handler {
  background-color: #F8EFE6;
}
.light > * .list-item {
  background-color: white;
}
.light > * .drag-handler {
  background-color: white;
}
.list-item {
  display: flex;
  margin: 5px;
  height: 50px;
  cursor: pointer;
  color: #5B5151;
  font-size: 20px;
  justify-content: space-between;
  min-width: 500px;
  border-radius: 0 4px 4px 0;
  flex-direction: row;
  line-height: 50px;
}
@media screen and (max-width: 1024px) {
  .list-item {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .list-item {
    font-size: 12px;
  }
}
.list-item > .name {
  text-align: left;
  flex-basis: 25%;
  min-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-item > .value,
.list-item > .value-list {
  text-align: center;
  width: 160px;
  min-width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-item > .value > .below,
.list-item > .value-list > .below {
  color: #67c2ef;
}
.list-item > .value > .above,
.list-item > .value-list > .above {
  color: #ff5454;
}
.list-item > .value > span:not(:last-child):after,
.list-item > .value-list > span:not(:last-child):after {
  content: " | ";
  color: #555555;
}
.list-item > .sensor-list {
  font-size: 20px;
  padding: 5px 5px 5px 20px;
  min-width: 50px;
  width: 50px;
  white-space: nowrap;
}
.list-item > .lastUpdate {
  text-align: center;
  width: 180px;
  min-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-item > .battery-column {
  padding: 12px;
}
.drag-handler {
  position: absolute;
  border-radius: 5px 0 0 5px;
  padding-top: 13px;
  display: inline-block;
  width: 20px;
  height: 50px;
}
