.access-control {
  background-color: #F1F1F1;
  height: calc(100vh - 54px);
  padding: 10px;
}
.access-control .left {
  margin: 0;
  padding: 30px 0 0 0;
  z-index: 10;
}
.access-control .right {
  margin-left: -1px;
  z-index: 1;
}
@media only screen and (max-width: 768px) {
  .access-control .right {
    padding: 0;
    margin: 0;
    margin-right: -15px;
    margin-left: -15px;
  }
}
.access-control .menu-item {
  height: 50px;
  width: 100%;
  padding-top: 11px;
  padding-left: 14px;
  font-size: 18px;
  border-style: solid;
  border-color: lightgray;
  border-width: 0 0 1px 1px;
  cursor: pointer;
}
@media only screen and (min-width: 768px) {
  .access-control .menu-item {
    border-radius: 12px 0 0 12px;
  }
}
.access-control .menu-item:first-child {
  border-top-width: 1px;
}
.access-control .menu-item.active {
  background-color: white;
}
.access-control .content {
  border-style: solid;
  border-color: lightgray;
  border-width: 1px;
  width: 102vw;
  background-color: white;
  padding: 20px;
  height: 100%;
}
@media only screen and (min-width: 768px) {
  .access-control .content {
    border-radius: 12px;
    min-height: 210px;
    width: auto;
  }
}
.access-control .content .language-dropdown button {
  color: black;
  background-color: white !important;
  width: 140px;
}
