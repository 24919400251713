.tos-main {
  margin: 10px auto;
}
.tos-main .scroll-area {
  height: calc(100vh - 52px) !important;
  width: 100%;
  margin: 0;
}
.tos-main .content {
  width: 80%;
  margin: auto;
}
