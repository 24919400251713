.icon-png {
  background: none;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 26px;
  height: 26px;
  cursor: pointer;
  color: inherit;
  border: none;
  padding: 0;
  outline: inherit;
  display: inline-block;
}
.icon-png.xlsx {
  background-image: url("../images/FileXlsxIcon.png");
}
.icon-png.pdf {
  background-image: url("../images/FilePdfIcon.png");
}
