.device-parameters {
  width: 99.5%;
  margin: auto;
  background-color: #fbfbfb;
}
.device-parameters.border-top {
  border-top: 1px solid lightgray;
}
.device-parameters .gaugeChart {
  margin: 10px auto 10px auto;
  padding: 5px;
  box-shadow: 0 0 8px lightgray;
  background-color: white;
  cursor: pointer;
  min-width: 300px;
  max-width: 300px;
  border-radius: 5px;
}
.device-parameters .gaugeChart .title {
  font-size: 20px;
  padding-top: 10px;
  text-align: center;
}
.device-parameters .parameters-collapse {
  padding: 0 0 0 20px;
  background-color: #F1F1F1;
  margin: 0;
  width: 100%;
  border-bottom: 1px solid #CCC;
}
.device-parameters .parameters-collapse .btn-tab {
  color: black;
  background-color: #F5F5F5;
  border: 1px solid #CCC;
  border-radius: 8px 8px 0 0;
  min-width: 124px;
  padding: 5px;
  cursor: pointer;
  margin: 0 0 -1px;
  display: inline-table;
  text-align: center;
}
.device-parameters .parameters-collapse .btn-tab span {
  opacity: 0.5;
}
.device-parameters .parameters-collapse .btn-tab:not(:last-child) {
  border-right: none;
}
.device-parameters .parameters-collapse .btn-tab:hover span {
  opacity: 0.75;
}
.device-parameters .parameters-collapse .btn-tab.active {
  background-color: #fbfbfb;
  border-bottom-color: #fbfbfb;
}
.device-parameters .parameters-collapse .btn-tab.active span {
  opacity: 1;
}
.device-parameters .react-datepicker__tab-loop {
  float: left;
}
