.about {
  margin: 10px auto;
}
.about .scroll-area {
  height: calc(100vh - 70px) !important;
  width: 100%;
  margin: 0;
}
.about .content {
  width: 80%;
  margin: auto;
}
.about .content .schema {
  border: 1px solid lightgray;
  border-radius: 20px;
  background-color: white;
  width: 800px;
  margin: auto;
  padding: 20px;
  position: relative;
  font-size: 20px;
}
.about .content .schema img {
  width: 754px;
  margin-top: 20px;
}
.about .content .schema div {
  position: absolute;
  width: 180px;
}
