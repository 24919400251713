.device-details-offsets {
  display: grid;
  grid-gap: 5px;
  grid-template-rows: 24px 1fr;
  margin-top: 10px;
}
.device-details-offsets .header {
  cursor: pointer;
  text-align: center;
}
