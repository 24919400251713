.input-range-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.input-range-container .slider-box {
  flex-grow: 10;
  margin: 10px 20px 0 20px;
}
.input-range-container .text-box {
  flex-grow: 1;
  max-width: 60px;
  min-width: 60px;
}
.input-range-container .text-box input {
  text-align: center;
}
.input-range-container .form-control.is-valid,
.input-range-container .form-control.is-invalid {
  background-image: none;
  padding-right: inherit;
}
.standard > * .input-range__track--active {
  background-color: #3ACFE5;
}
.standard > * .input-range__slider {
  background-color: #3ACFE5;
  border: 1px solid #3ACFE5;
}
.light > * .input-range__track--active {
  background-color: #04A6D8;
}
.light > * .input-range__slider {
  background-color: #04A6D8;
  border: 1px solid #04A6D8;
}
.input-range__slider {
  width: 1.4rem;
  height: 1.4rem;
  margin-left: -0.8rem;
  margin-top: -0.8rem;
}
.input-range__label-container {
  top: -2px;
}
.input-range__label--max .input-range__label-container {
  left: 0;
}
.input-range__label {
  font-size: 11px;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
}
.input-range__track--background {
  background-color: #bbb;
}
