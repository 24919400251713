.leaflet-container {
  height: calc(100vh - 50px);
  width: 100%;
  margin: 0 auto;
}
.marker-popup {
  min-width: 250px;
  font-size: 14px;
}
.marker-popup .date-header {
  text-align: center;
  font-size: 11px;
  margin: -4px 5px 0 0;
}
.marker-popup .title {
  padding: 10px 5px 5px 5px;
}
.marker-popup .marker-popup-row {
  padding: 10px 0 10px 0;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
}
.marker-popup .marker-popup-row.not-clickable {
  cursor: default;
}
.marker-popup .marker-popup-row.selected {
  background-color: #fafdff;
}
.marker-popup .marker-popup-row:last-child {
  border-bottom: none;
}
.marker-popup .marker-popup-row .icon {
  font-size: 18px;
  position: relative;
  top: 4px;
}
.marker-popup .marker-popup-row .name {
  margin-left: 5px;
}
.marker-popup .marker-popup-row .value {
  float: right;
  font-size: 18px;
  min-width: 52px;
}
.marker-popup .marker-popup-row .value .unit {
  font-size: 11px;
  padding-left: 2px;
}
.marker-popup .marker-popup-row .quality-index {
  display: inline-block;
  min-width: 26px;
  height: 26px;
  font-size: 22px;
  float: right;
  margin-right: 14px;
  margin-top: -2px;
  cursor: pointer;
}
.marker-popup .marker-popup-row .quality-index div {
  font-weight: bold;
  padding: 2px 4px;
  position: initial;
  line-height: 18px;
}
.map-no-points {
  position: fixed;
  top: 200px;
  left: calc(50% - 100px);
  z-index: 10000;
}
.map-marker.undefined {
  fill: gray;
  stroke: gray;
}
.map-marker.offnormal {
  fill: red;
  stroke: red;
}
.map-marker.normal {
  fill: green;
  stroke: green;
}
.external-source-switch {
  z-index: 400;
  position: absolute;
  right: 10px;
  top: 70px;
}
.external-source-switch .btn-check {
  position: absolute;
  visibility: hidden;
  display: none;
}
.external-source-switch label:first-of-type {
  border-radius: 4px 0 0 4px !important;
}
.external-source-switch .btn {
  cursor: pointer;
}
.external-source-switch .btn.active {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5) !important;
}
.logo-map {
  position: absolute;
  right: 30px;
  top: 80px;
  z-index: 999;
  width: 180px;
  display: none;
}
@media (min-width: 768px) {
  .logo-map {
    display: block;
  }
}
.icon-marker.quality-1 div {
  background-color: #84D54C;
}
.icon-marker.quality-2 div {
  background-color: #54D3FF;
}
.icon-marker.quality-3 div {
  background-color: #ECCC42;
}
.icon-marker.quality-4 div {
  background-color: #EC9246;
}
.icon-marker.quality-5 div {
  background-color: #ED6052;
}
.icon-marker.quality-6 div {
  background-color: #C03B6A;
}
.icon-marker .marker-dot {
  border-width: 1px;
  opacity: 1;
  filter: blur(0px) !important;
  border: 2px solid;
  width: 15px;
  height: 15px;
  border-radius: 8px;
  margin: 7px 0 0 7px;
  border-color: white;
}
.icon-marker .blur-icon-marker {
  filter: blur(12px);
  border-radius: 15px;
  opacity: 0.9;
  width: 30px;
  height: 30px;
  margin-top: -22px;
}
