.light > * .main-dashboard-container {
  background-color: #F1F1F1;
  border: 0;
}
.light > * .main-dashboard-container .view-switch {
  border: 0;
}
.light > * .main-dashboard-container .view-switch .active {
  background-color: #04A6D8;
}
.standard > * .main-dashboard-container {
  background-color: #fbfbfb;
}
.standard > * .main-dashboard-container .view-switch {
  border-bottom: 1px solid lightgray;
}
.standard > * .main-dashboard-container .view-switch .active {
  background-color: #3ACFE5;
}
.main-dashboard-container .row.no-margin {
  margin: 0;
}
.main-dashboard-container .view-switch {
  background-color: #F1F1F1;
  width: 100%;
  padding: 12px;
  margin-bottom: 2px;
}
@media (max-width: 768px) {
  .main-dashboard-container .view-switch {
    display: none;
  }
}
.main-dashboard-container .view-switch i {
  cursor: pointer;
}
.main-dashboard-container .view-switch .active {
  color: white;
  height: 24px;
  width: 25px;
  display: inline-block;
  border-radius: 4px;
}
.main-dashboard-container .scrollarea-right {
  height: calc(100vh - 120px) !important;
}
.main-dashboard-container .list-item-header {
  display: flex;
  font-size: 12px;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 30px 0 10px;
}
.main-dashboard-container .list-item-header > .sensor-list {
  min-width: 50px;
  width: 50px;
}
.main-dashboard-container .list-item-header > .key {
  text-align: left;
  width: 100px;
}
.main-dashboard-container .list-item-header > .name {
  text-align: left;
  flex-basis: 25%;
  min-width: 150px;
}
.main-dashboard-container .list-item-header > .status {
  min-width: 32px;
  width: 32px;
}
.main-dashboard-container .list-item-header > .value {
  width: 160px;
  text-align: center;
  min-width: 60px;
}
.main-dashboard-container .list-item-header > .lastUpdate {
  width: 200px;
  text-align: center;
  width: 180px;
  min-width: 120px;
}
.main-dashboard-container .list-item-header > .battery-box {
  width: 75px;
  text-align: center;
}
