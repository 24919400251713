.device-actions {
  padding: 10px;
}
.device-actions i {
  position: relative;
  top: 4px;
  font-size: 20px;
  margin-right: 4px;
}
.device-actions .modal {
  top: 50px;
}
.device-actions .actions {
  padding-right: 30px;
}
.device-actions .actions .action {
  background-color: #F6F6F6;
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 5px 10px 5px 10px;
  margin: 5px;
  width: 100%;
}
.device-actions .actions .action button {
  display: inline-block;
  margin: 10px 10px 10px 0;
}
.device-actions .actions .action .description {
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 14px;
}
